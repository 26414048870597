// message types
export const MESSAGE_TYPE = Object.freeze({
    TEXT: 'text',
    ATTACHMENT: 'attachment',
    URL: 'url' // not in use currently
})

// file types
export const ATTACHMENT_TYPES = Object.freeze({
    PNG: 'png',
    JPG: 'jpg',
    JPEG: 'JPEG',
    SVG: 'svg',
    FILE: 'pdf'
})