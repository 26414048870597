<template>
  <div>
    <header class="sa-header sa-header-border sa-header-fixed">
        <button @click="$router.go(-1)" class="sa-btn-back">
        <i class="icon-arrow-left"></i> 
      </button>{{$t('chat')}}
      <!-- <i @click="videoCall" class="icon-video sa-video-icon"></i> -->
    </header>
    <chat-compo 

    />
  </div>
</template>
<script>
import ChatCompoVue from '../../components/chat/ChatCompo.vue'
export default {
    components: {
        'chat-compo': ChatCompoVue
    },
    data() {
        return {
            
        }
    },
    methods: {
    //   videoCall() {
        
    //   }
    },
    async mounted() {
    },
}
</script>
