import Firebase from 'firebase'

const config = {
    apiKey: "AIzaSyBTNkld9FwLC3_YI1MXLwvFizpeEfW7Jp8",
    authDomain: "sanarapp-production.firebaseapp.com",
    databaseURL: "https://sanarapp-production.firebaseio.com",
    projectId: "sanarapp-production",
    storageBucket: "sanarapp-production.appspot.com",
    messagingSenderId: "334112692867",
    appId: "1:334112692867:web:4ac201a1955f08dd8e99b6",
    measurementId: "G-L1QSPLFZ19"
  };
const firebaseApp = !Firebase.apps.length ? Firebase.initializeApp(config) : Firebase.app();

// Export the database for components to use.
// If you want to get fancy, use mixins or provide / inject to avoid redundant imports.
export const db = firebaseApp.database();