<template>
  <div class="sa-content" v-if="apnInfo != null && !loader">
    <div class="sa-chat-card" >
      <div class="sa-chat-history" ref="chatArea" @click="onScreenTap">
        <div class="sa-chat-about">
          <figure class="sa-avtar sa-avtar-sm sa-avtar-circle">
            {{ firstLetters }}
          </figure>
          <h6>
            {{ apnInfo.p_fn }} {{ apnInfo.p_ln }} 
            <small class="sa-d-block sa-font-normal">Active Now</small>
          </h6>
          <small
            >Your connection started. Now you<span class="sa-d-block"
              >can chat with the other end</span
            ></small
          >
        </div>
        <div v-for="(m, k) in messages" :key="k">
          <div :class="m.sender_type == 1 ? 'sa-msg-doc' : 'sa-chat-msg'">
            <div class="sa-chat-msg-info">
              <div
                v-if="m.msg_type == messageType.TEXT"
                class="sa-chat-msg-text"
                id="send"
              >
                {{ m.body }}
                <div class="sa-chat-time">{{ time(m.timestamps) }}</div>
              </div>
              <div v-else class="sa-chat-msg-text">
                <a
                  :href="m.body"
                  target="_blank"
                  v-if="m.file_type == attachmentType.JPG || attachmentType.JPEG || attachmentType.PNG || attachmentType.SVG"
                  ><img :src="m.body" class="sa-img-cover"
                /></a>
                <a
                  :href="m.body"
                  target="_blank"
                  v-if="m.file_type == attachmentType.FILE"
                  ><i class="icon-pdf"></i
                ></a>
                <div class="sa-chat-time">{{ time(m.timestamps) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sa-nav-bar-fixed sa-fixed-no-radius sa-chat-footer">
        <input
          type="text"
          @keyup.enter="sendMessage"
          class="sa-chat-input"
          :value="msgText"
          @input="evt=>msgText=evt.target.value"
          ref="chatInput"
          placeholder="Enter your message..."
        />
        <div>
          <!-- <multi-file-upload
            v-on:file-upload-success="imageUpload"
            :show-delete="true"
            @remove-file="removeFile"
            :fileData="reportsfile"
          /> -->
          <button @click="sendMessage" class="sa-btn sa-chat-btn" >
            <i class="icon-send"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import { db } from "../../firebase";
import FileUploadMultipleVue from "../../components/common/FileUploadMultiple.vue";
import { MESSAGE_TYPE, ATTACHMENT_TYPES } from "../../util/common";
import moment from "moment";
export default {
  data() {
    return {
      loader:false,
      apnInfo: null,
      messages: {},
      attachment: false,
      msgText: "",
      imgType: null,
      msgType: "text",
      reportsfile: [],
      messageType: MESSAGE_TYPE,
      attachmentType: ATTACHMENT_TYPES,
    };
  },
  components: {
    "multi-file-upload": FileUploadMultipleVue,
  },
  methods: {
    onScreenTap: function() {
      this.$refs.chatInput.blur();
    },
     time: function(val) {
      return moment(val).format("h:mm a");
    },
    removeFile: function(file) {
      this.reportsfile = this.reportsfile.filter((_) => _.url !== file.url);
      this.msgText = "";
    },
    imageUpload: function(val) {
      this.attachment = true;
      this.imgType = val.filename.split(".").pop();
      this.reportsfile.push({ url: val.url, file_nme: val.filename });
      this.msgText = val.url;
    },
    sendMessage: async function() {

      if(!this.msgText || (typeof this.msgText === 'string' && !this.msgText.trim())){
        this.msgText = "";
        this.$refs.chatInput.focus();
        return;
      }
      this.$refs.chatInput.focus();
      let chatMessages = {
        body: this.msgText,
        chatroom_id: this.apnInfo.apnt_id,
        sender_type: 1,
        timestamps: new Date().getTime()
      }
      if (this.attachment) {
        chatMessages = {
          ...chatMessages,
          msg_type: "attachment",
          file_type: this.imgType,
        };
      } else {
        chatMessages = {
          ...chatMessages,
          msg_type: this.msgType,
        };
      }
      
      if(this.msgText.trim()){
        this.msgText = "";
        await this.$store.dispatch("appointments/INIT_CHAT",chatMessages);
      }
      this.scrollToEnd()
      this.reportsfile = [];
      // this.attachment = false;
    },
    scrollToEnd: function() {
      setTimeout(() => {
        let _self = this;
        let msgDsp = _self.$refs.chatArea;
        if(msgDsp && msgDsp.scrollHeight){
          msgDsp.scrollTop = msgDsp.scrollHeight;
        }
      }, 100);
    },
  },
  mounted: async function() {
    this.apnInfo =  JSON.parse(localStorage.getItem("activeAppointmentObj"));
    var ref = db.ref("ProviderChatMessages/" + this.apnInfo.apnt_id);
    ref.on("value", (snapshot) => {
      this.messages = snapshot.val();
      this.scrollToEnd();
    });
  },
  computed: {
    firstLetters: function() {
      let firstName = this.apnInfo.p_fn;
      let lastName = this.apnInfo.p_ln;
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    },
  },
};
</script>
